﻿module.exports = {
    errors: {
        select_file_to_check: 'Selecteer een bestand om te controleren',
        specify_starting_row: 'Geef aan op welke rij de data begint',
        invalid_starting_row: 'De rij waarop de data begint, is ongeldig',
        specify_jobtype: 'Geef aan welke controle moet worden uitgevoerd'
    },
    modal: {
        code: 'Code',
        lineNumber: 'Regel',
        message: 'Bericht',
        title: 'ABC fouten en waarschuwingen',
    },
    details: {
        backToOverview: 'Terug naar overzicht',
    },
    newJob: {
        breadcrumb: 'Start nieuwe controle',
        countryOfDestination: 'Land van bestemming',
        countryOfOrigin: 'Land van oorsprong',
        errorMessages: {
            columnNameCannotBeEmpty: 'Kolomnaam mag niet leeg zijn',
            invalidColumnName: 'Ongeldige kolomnaam',
            duplicateColumnName: 'Dubbele kolomnaam',
        },
        jobTypes: {
            description: 'Soort controle'
        },
        labelUseTemplateOrCustomCheck: 'Kies een ABC-controle uit de lijst of stel een eigen controle samen',
        optionCustomCheck: 'Zelf samenstellen',
        measureTypes: 'Type maatregelen',
        outputFormats: {
            csv: 'CSV',
            description: 'Formaat uitvoer',
            excel: 'Excel',
            excelAnnotated: 'Excel met kleur-indicators',
            plainText: 'Plain text'
        },
        outputType: 'Geef aan wat u in de output wil ontvangen',
        readFile: 'Geef aan in welke rijen en kolommen we de relevante data kunnen vinden',
        selectFile: 'Selecteer een Excel-bestand om te verwerken',
        openFile: 'Open bestand',
        selectedFile: 'Geselecteerde bestand',
        startCheck: 'Start controle',
        startsOnRow: 'Begint op rij',
        title: 'ABC - Nieuwe bestandscontrole starten',
        uploadFile: 'Bestand uploaden'
    },
    overview: {
        breadcrumb: 'Taric Support ABC',
        contactUs: '<a href="http://www.taricsupport.nl/contact" target="_blank">Neem contact met ons op</a> voor meer informatie over Taric Support ABC en de mogelijkheden voor uw organisatie',
        dateAdded: 'Toegevoegd op',
        dateCompleted: 'Afgerond op',
        description: 'Valideer snel grote aantallen goederencodes. Upload een Excel-bestand en verrijk het bestand met informatie uit het douanetarief, zoals omschrijvingen van de nomenclatuur, aanvullende codes, invoerrechten, btw-tarieven en bescheidcodes.',
        documentType: 'Type',
        downloadFile: 'Download bestand',
        file: 'Bestand',
        noJobs: 'U heeft nog geen bestanden gecontroleerd.',
        numberOfErrors: '# Fouten',
        numberOfLines: '# Regels',
        numberOfLogs: '# Berichten',
        numberOfWarnings: '# Waarschuwingen',
        showDetails: 'Details bekijken',
        startFirst: 'Start uw eerste controle',
        startNew: 'Start een nieuwe controle',
        status: 'Status',
        title: 'Taric Support ABC - Automatische bestandscontrole',
    },
    status: {
        cancel_task_areyousure: 'Weet u zeker dat u deze taak wil annuleren?'
    }
};
