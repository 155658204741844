﻿module.exports = {
    controller: complianceNewController,
    templateUrl: 'compliance/components/compliance-new-page.html'
};

complianceNewController.$inject = [
    '$analytics', 
    '$translate', 
    'common', 
    'complianceDataService', 
    'language', 
    'applicationStateService', 
    'Upload'
];

function complianceNewController(
    $analytics, 
    $translate, 
    common, 
    complianceData, 
    languageService, 
    state, 
    fileUpload
) {

    var vm = {
        $onInit: attached,
        errors: [],
        appStateFilter: state.filter,
        getFileDetails: getFileDetails,
        inputColumnChanged: inputColumnChanged,
        isUploading: false,
        jobTemplates: [],
        jobTypeChanged: jobTypeChanged,
        jobTypes: [],
        newJob: {
            file: null,
            inputColumns: null,
            jobType: '',
            movement: '0',
            name: null,
            outputType: '2',
            startRow: {
                value: 2
            }
        },
        save: saveJob,
        selectedJobType: null,
        selectedTemplate: null,
        translations: []
    };

    function attached() {
        common.auth.check();
        $analytics.pageTrack('/abc-new');
        $translate([
            'compliance.errors.select_file_to_check', 
            'compliance.errors.specify_goodsnom_column', 
            'compliance.errors.invalid_goodsnom_column', 
            'compliance.errors.invalid_country_code_column', 
            'compliance.errors.specify_starting_row', 
            'compliance.errors.invalid_starting_row', 
            'compliance.errors.specify_jobtype'
        ])
            .then(function (result) {
                vm.translations = result;
            });

        getJobTemplates();
        getJobTypes();
    }

    function getFileDetails(e) {
        for (var i = 0; i < e.length; i++) {
            vm.newJob.name = e[i].name;
        }
    }

    function getJobTemplates() {
        complianceData.getJobTemplates()
            .then(function (data) {
                vm.jobTemplates = data;
                if (vm.jobTemplates.length > 0) {
                    vm.selectedTemplate = vm.jobTemplates[0].Id;
                }
            });
    }

    function getJobTypes() {
        complianceData.getJobTypes()
            .then(function (data) {
                vm.jobTypes = data;
                if (vm.jobTypes && vm.jobTypes.length > 0) {
                    vm.newJob.jobType = vm.jobTypes[0].JobType;
                    jobTypeChanged();
                }
            });
    }

    function inputColumnChanged() {
        validateInputColumns();
    }

    function validateInputColumns() {
        let numberOfValidationErrors = 0;
        let columnNameCount = {};
        _.forEach(vm.newJob.inputDataTypes, function (inputDataType) {
            _.forEach(vm.newJob.inputColumns[inputDataType], function (value) {
                value.error = null;
                if (!value.name) {
                    numberOfValidationErrors++;
                    value.error = {
                        message: 'columnNameCannotBeEmpty'
                    };
                }
                else if (!value.name.match(/^[a-zA-z]+$/)) {
                    numberOfValidationErrors++;
                    value.error = {
                        message: 'invalidColumnName'
                    };
                }
                else {
                    value.name = value.name.toUpperCase().replace(/[^A-Z]/g, '');
                    if (columnNameCount[value.name] === undefined) {
                        columnNameCount[value.name] = 1;
                    }
                    else {
                        columnNameCount[value.name]++;
                    }
                }
            });
        });
        const duplicateColumnNames = _.chain(columnNameCount)
            .pickBy(count => count > 1)
            .keys()
            .value();

        _.forEach(vm.newJob.inputDataTypes, function (inputDataType) {
            _.forEach(vm.newJob.inputColumns[inputDataType], function (value) {
                if (duplicateColumnNames.indexOf(value.name) !== -1) {
                    numberOfValidationErrors++;
                    value.error = {
                        message: 'duplicateColumnName'
                    };
                }
            });
        });
        return numberOfValidationErrors;
    }

    function jobTypeChanged() {
        vm.selectedJobType = vm.jobTypes.find(jt => jt.JobType === vm.newJob.jobType);

        vm.newJob.inputDataTypes = _.chain(vm.selectedJobType.InputColumns)
            .map(c => c.Id)
            .uniq()
            .value();
        vm.newJob.inputColumns = _.chain(vm.selectedJobType.InputColumns)
            .keyBy(c => c.Id)
            .mapValues(c => c.DefaultColumnIndices
                .map(i => ({ 
                    label: c.Description,
                    name: columnIndexToExcel(i)
                }))
            )
            .value();
    }

    function validate(fromTemplate) {
        vm.errors = [];
        if (!vm.newJob.file) {
            vm.errors.push(vm.translations['compliance.errors.select_file_to_check']);
        }

        if (!fromTemplate) {
            
            if (!vm.newJob.startRow.value) {
                vm.errors.push(vm.translations['compliance.errors.specify_starting_row']);
            }
            else if (!('' + vm.newJob.startRow.value).match(/^[1-9][0-9]*$/)) {
                vm.errors.push(vm.translations['compliance.errors.invalid_starting_row']);
            }
    
            if (!vm.newJob.jobType) {
                vm.errors.push(vm.translations['compliance.errors.specify_jobtype']);
            }
        }

        var numberOfErrors = vm.errors.length + validateInputColumns();


        return numberOfErrors === 0;
    }

    function saveJob() {
        var usingTemplate = vm.selectedTemplate !== null;
        if (!validate(usingTemplate)) {
            return;
        }

        vm.isUploading = true;

        if (vm.selectedTemplate) {
            complianceData.createJobFromTemplate(vm.selectedTemplate)
                .then(function (jobResult) {
                    fileUpload.upload({
                        data: { file: vm.newJob.file },
                        headers: { 'Authorization': 'Bearer ' + common.identity.token.tokenValue },
                        url: common.config.apiHosts.generic + '/compliance/job/' + jobResult.Id + '/document?template=' + vm.selectedTemplate
                    })
                        .then(function (fileResult) {
                            // At last, finalize the job
                            complianceData.finalizeJob(jobResult.Id)
                                .then(function (finalizeResult) {
                                    common.$location.url('/compliance-check');
                                });
                        });
                });
        }
        else {

            var job = {
                jobType: vm.newJob.jobType,
                language: languageService.get(),
                movement: vm.newJob.movement,
                outputType: parseInt(vm.newJob.outputType),
                referenceCountry: state.filter.referenceCountry
            };

            // First create the job
            complianceData.createJob(job)
                .then(function (jobResult) {

                    var urlArgs = [
                        'docType=Excel',
                        'startingLine=' + (vm.newJob.startRow.value - 1),
                        'inputColumns=' + vm.newJob.inputDataTypes
                            .map(id => id + '|' + vm.newJob.inputColumns[id]
                                .map(c => excelColumnToIndex(c.name))
                                .join(',')
                            ).join(';') 
                    ];
                    //Then upload the file
                    fileUpload.upload({
                        data: { 
                            file: vm.newJob.file
                        },
                        headers: { 'Authorization': 'Bearer ' + common.identity.token.tokenValue },
                        url: common.config.apiHosts.generic + '/compliance/job/' + jobResult.Id + '/document?' + urlArgs.join('&')
                    })
                        .then(function (fileResult) {
                            // At last, finalize the job
                            complianceData.finalizeJob(jobResult.Id)
                                .then(function (finalizeResult) {
                                    common.$location.url('/compliance-check');
                                });
                        });
                });
        }
    }

    // Converts Excel column name to zero-based index
    function excelColumnToIndex(columnName) {
        columnName = columnName.toUpperCase();
        let sum = 0;
        for (let i = 0; i < columnName.length; i++)
        {
            sum *= 26;
            sum += (columnName[i].charCodeAt() - 'A'.charCodeAt() + 1);
        }
        return sum - 1;
    }

    // Converts zero-based index to Excel column name
    function columnIndexToExcel(index) {
        let columnName = String.fromCharCode(65 + (index % 26));
        if (index >= 26) {
            columnName = String.fromCharCode(64 + index / 26) + columnName;
        }
        return columnName;
    }

    return vm;

}
