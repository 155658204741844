﻿module.exports = {
    historicSearchWarning: 'Let op: de datum is niet ingesteld op vandaag',
    loggingOut: 'Uw account wordt op een andere locatie gebruikt. Het is niet mogelijk om hetzelfde account vanaf meerdere locaties te gebruiken. U wordt uitgelogd.',
    PleaseContactTaricSupportForAccessToTradeTariff: 'Neem contact op met Taric Support voor toegang tot het douanetarief van dit land.',
    unauthorized: 'Uw account beschikt niet over de juiste rechten om deze functionaliteit te gebruiken.',
    pickCountryForVatRates: 'Kies een land om de btw-tarieven te zien',
    DataNotAvailableForSwissTradeTariff: 'Deze gegevens zijn niet beschibaar voor het Zwitserse douanetarief.',
    ExchangeRatesNotAvailableForSwissTradeTariff: 'De wisselkoersen zijn op het moment niet beschikbaar voor het Zwitserse douanetarief. Als u interesse heeft in deze module, neem dan contact met ons op.',
    TransactionValueCalculatorNotAvailableForSwissTradeTariff: 'De transactiewaardecalculator is op het moment niet beschikbaar voor het Zwitserse douanetarief.',
    textNewerThanRequested_StartDate_: 'NB: deze tekst is niet beschikbaar voor de door u gekozen datum. De onderstaande tekst is een latere versie, geldig vanaf {{ StartDate | date : \'dd-MM-yyyy\' }}.',
    NoWcoAccessMessage: 'Voor toegang tot de Toelichtingen en Indelingsadviezen van de WCO is een apart abonnement nodig. Neem contact met ons op voor meer informatie.',
};
