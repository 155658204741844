﻿module.exports = {
    historicSearchWarning: 'Note: date is not set to today',
    loggingOut: 'Your account is being used at a different location. It is not possible to use the same account from multiple locations. You are being logged out.',
    PleaseContactTaricSupportForAccessToTradeTariff: 'Please contact Taric Support for access to this trade tariff.',
    unauthorized: 'Your account does not have the proper permissions to access this functionality.',
    pickCountryForVatRates: 'Choose a country to see the applicable VAT rates',
    DataNotAvailableForSwissTradeTariff: 'This data is not available for the Swiss trade tariff.',
    ExchangeRatesNotAvailableForSwissTradeTariff: 'The exchange rates are not currently available for the Swiss trade tariff. If you are interested in this module, please let us know. ',
    TransactionValueCalculatorNotAvailableForSwissTradeTariff: 'The transaction value calculator is not currently available for the Swiss trade tariff.',
    textNewerThanRequested_StartDate_: 'Note: this text is not available for the date you\'ve chosen. The text below is a later version. It is valid from {{ StartDate | date : \'dd-MM-yyyy\' }}.',
    NoWcoAccessMessage: 'For access to the WCO Explanatory Notes and Classification Opinions a separate subscription is necessary. Please contact us for more information',
};
