module.exports = {
    bindings: {
        countryNames: '<',
        firstColumnLabel: '<',
        result: '<',
        tradeMovement: '<'
    },
    bindToController: true,
    controller: tradeStatisticsTableController,
    templateUrl: 'trade-statistics/components/trade-statistics-table.html'
};

tradeStatisticsTableController.$inject = [];

function tradeStatisticsTableController() {

    var vm = {
        onClickSortBy: onClickSortBy,
        sorting: {
            columnName: 'country',
            sortOrder: 'asc'
        }
    };

    return vm;

    function onClickSortBy(columnName) {
        var sortOrder;
        if (vm.result) {
            if (columnName === vm.sorting.columnName) {
                sortOrder = vm.sorting.sortOrder === 'asc' ? 'desc' : 'asc';
            }
            else if (columnName === 'import_value' || columnName === 'import_weight' || columnName === 'export_value' || columnName === 'export_weight') {
                sortOrder = 'desc';
            }
            else {
                sortOrder = 'asc';
            }
            vm.sorting.columnName = columnName;
            vm.sorting.sortOrder = sortOrder;
            sortResultsBy(columnName, sortOrder)
        }
    }

    function sortResultsBy(columnName, sortOrder) {
        var year = vm.result.years[0];
        if (columnName === 'country') {
            vm.result.rows = _.orderBy(vm.result.rows, function (row) {
                return vm.countryNames[row.country_id];
            }, [ sortOrder ]);
        }
        vm.result.rows = _.orderBy(vm.result.rows, function(row) {
            return row.samples[year][columnName];
        }, [ sortOrder ]);
    }

}