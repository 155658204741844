﻿module.exports = {
    controller: footnoteOverviewController,
    templateUrl: 'footnotes/components/footnote-overview-page.html'
};

footnoteOverviewController.$inject = [
    '$analytics', 
    '$timeout',
    'notificationsService', 
    'footnoteDataService', 
    'applicationStateService'
];

function footnoteOverviewController(
    $analytics,
    $timeout, 
    notifications, 
    footnoteData, 
    state
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        filter: "",
        filterType: "",
        isLoading: false,
        isLoadingTypes: false,
        footnotes: [],
        filteredFootnotes: [],
        footnoteTypes: [],
        filteredFootnoteTypes: [],
        selectedType: null,
        referenceCountry: state.filter.referenceCountry,
        loadFootnote: loadFootnotes,
        clearFilter: clearFilter,
        changeFilter: changeFilter,
        clearFilterType: clearFilterType,
        changeFilterType: changeFilterType,
        focusInputTypeField: focusInputTypeField,
        focusInputField: focusInputField
    };


    function focusInputTypeField() {
        $timeout(function(){
            var inputEL = angular.element(document.querySelector('#inputFilterType'));
            if (inputEL) {
                inputEL[0].focus();
            }
        }, 0);
    }

    function focusInputField() {
        $timeout(function(){
            var inputEL = angular.element(document.querySelector('#inputFilter'));
            if (inputEL) {
                inputEL[0].focus();
            }
        }, 0);
    }

    function attached() {
        $analytics.pageTrack('/reference/footnotes');
        loadTypes();
        notifications.subscribe('filter_date_changed', dateChanged);
    }

    function detached() {
        notifications.unsubscribe('filter_date_changed', dateChanged);
    }

    function dateChanged() {
        loadTypes();
    }

    function clearFilter() {
        vm.filter = '';
        vm.filteredFootnotes = vm.footnotes;
        focusInputField();
    }

    function changeFilter() {
        vm.filteredFootnotes = vm.footnotes.filter(function (el) {
            return (el.type.toLowerCase() + el.code.toLowerCase()).includes(vm.filter.toLowerCase()) || 
                el.description.toLowerCase().includes(vm.filter.toLowerCase());
        });
    }

    function clearFilterType() {
        clearFilter();
        vm.filterType = "";
        vm.filteredFootnoteTypes = vm.footnoteTypes;
        vm.footnotes = [];
        vm.filteredFootnotes = [];
        vm.selectedType = null;
        focusInputTypeField();
    }

    function changeFilterType() {
        vm.filteredFootnoteTypes = vm.footnoteTypes.filter(function (el) {
            return el.code.toLowerCase().includes(vm.filterType.toLowerCase()) || el.description.toLowerCase().includes(vm.filterType.toLowerCase());
        });
        if(vm.filteredFootnoteTypes.length === 1) {
            loadFootnotes(vm.filteredFootnoteTypes[0]);
        }
        if(vm.filteredFootnoteTypes.length === 0) {
            vm.footnotes = [];
            vm.filteredFootnotes = [];
            vm.selectedType = null;
            vm.filter = "";
        }
    }


    function loadFootnotes(type) {
        if (vm.referenceCountry === 'CH') {
            return;
        }
        vm.isLoading = true;
        vm.selectedType = type.code;
        vm.filter = "";
        footnoteData.getFootnotes(type.code)
            .then(function (data) {
                vm.filteredFootnotes = data;
                vm.footnotes = data;
                vm.isLoading = false;
                focusInputField();
            });
    }

    function loadTypes() {
        if (vm.referenceCountry === 'CH') {
            return;
        }
        vm.isLoadingTypes = true;
        vm.footnotes = [];
        vm.filteredFootnoteTypes = [];
        vm.filter = "";
        vm.filterType = "";
        vm.selectedType = null;
        footnoteData.getTypes()
            .then(function (data) {
                vm.footnoteTypes = data;
                vm.filteredFootnoteTypes = data;
                vm.isLoadingTypes = false;
                focusInputTypeField();
            });
    }

    return vm;

}
