﻿module.exports = {
    bindings: {
        measures: '<'
    },
    controller: regulationButtonController,
    templateUrl: 'regulations/components/regulation-button.html'
};

regulationButtonController.$inject = ['$q', 'regulationModal', 'regulationsDataService'];

function regulationButtonController($q, regulationModal, regulationsData) {

    var vm = {
        showRegulation: showRegulation
    };

    function showRegulation() {
        return $q.all(
            _.chain(vm.measures)
                .filter(function(measure) {
                    return !!measure.regulation;
                })
                .uniqBy(function(measure) {
                    return measure.type + '|' + measure.regulation.id + '|' + measure.region.code;
                })
                .orderBy(function(measure) {
                    return measure.regulation.reg_pubdate;
                })
                .map(function(measure) {
                    return regulationsData
                        .getRegulation(measure.regulation.id)
                        .then(function(regulation) {
                            measure.regulation.eurlex_regulation = regulation;
                            return measure;
                        });
                })
                .value()
            ).then(function(measures) {
            // console.log(measures);
            regulationModal.show(measures);
        });
    }


    return vm;

}