﻿module.exports = {
    controller: searchController,
    templateUrl: 'search/components/pages/search.html'
};

searchController.$inject = [
    '$analytics',
    '$routeParams', 
    'common', 
    'dataContext', 
    'formatting',
    'btiDataService', 
    'btiModal', 
    'caseLawModal', 
    'classificationRegulationsModal', 
    'nomenclatureDataService',
    'nomenclatureTreeService', 
    'notificationsService', 
    'applicationStateService', 
    'searchService',
    'referenceDateService',
    'ecicsDataService',
];

function searchController(
    $analytics, 
    $routeParams, 
    common, 
    dataContext, 
    formatting, 
    btiDataService,
    btiModal, 
    caseLawModal, 
    classificationRegulationsModal, 
    nomenclatureDataService,
    nomenclatureTreeService, 
    notifications,
    state, 
    searchService,
    referenceDateService,
    ecicsDataService
) {

    var vm = {
        $onInit: attached,
        $onDestroy: detached,
        q: {},
        searchQuery: '',
        chapters: [],
        ecicsCount: {},
        isLoading: false,
        state: state,
        goHome: goHome,
        clickRow: clickRow,
        doubleClickRow: doubleClickRow,
        deselectLine: deselectLine,
        hasAtr: hasAtr,
        hasBti: hasBti,
        loadChapter: loadChapter,
        matchedGoodsCode: false,
        queryIsValid: queryIsValid,
        search: search,
        showBti: showBti,
        showCaseLaw: showCaseLaw,
        showClassificationRegulations: showClassificationRegulations
    };

    function attached() {
        common.auth.check();
        var referenceDate = common.$location.search().date;
        if (referenceDate) {
            referenceDateService.setReferenceDate(referenceDate);
        }
        // This is to support the older style links. This is only used with searchquery equal to a commodity code.
        if ($routeParams.searchquery) {
            if (common.formatting.isCommodityCode($routeParams.searchquery)) {
                common.$location.path('search/tree').search({ 
                    commodityCode: common.formatting.cleanGoodsCode($routeParams.searchquery) 
                }).replace();
            }
            else {
                common.$location.path('search/headings').search({ 
                    query: $routeParams.searchquery 
                }).replace();
            }
        }
        else {
            vm.q = searchService.parseLocationSearchObject(common.$location.search());
            loadSearchResults(vm.q);
        }
        notifications.subscribe('filter_movement_changed', movementChanged);
        notifications.subscribe('filter_date_changed', dateChanged);
    }

    function detached() {
        notifications.unsubscribe('filter_movement_changed', movementChanged);
        notifications.unsubscribe('filter_date_changed', dateChanged);
    }

    function movementChanged() {
        loadSearchResults(vm.q);
    }

    function dateChanged() {
        loadSearchResults(vm.q);
    }

    function goHome() {
        common.$location.url('');
    }

    function clickRow(heading) {
        state.currentCode = heading.value.commodityCode;
        state.currentIndentation = heading.value.indentation;
        if (!heading.hasChildren) {
            common.$location.url('good/' + heading.value.commodityCode);
        }
        else {
            heading.metadata.isExpanded = !heading.metadata.isExpanded;
        }
    }

    function doubleClickRow(heading) {
        state.currentCode = heading.value.commodityCode;
        state.currentIndentation = heading.value.indentation;
        if (!heading.hasChildren) {
            common.$location.url('good/' + heading.value.commodityCode);
        }
        else if (heading.metadata.isExpanded) {
            nomenclatureTreeService.collapseAll(heading);
        }
        else {
            nomenclatureTreeService.expandAll(heading);
        }
        common.interaction.clearSelection();
    }

    function setSectionAndChapter(sectionNumber, chapterNumber) {
        
        return nomenclatureDataService.getSections()
            .then(function(wrapper) {
                vm.section = _.find(wrapper.data, function(section) {
                    return section.number === sectionNumber;
                });
                vm.chapter = _.find(vm.section.chapters, function(chapter) {
                    return chapter.number === chapterNumber;
                });
            });
    }

    function queryIsValid(q) {
        if (q.query) {
            return q.query.length > 1;
        }
        else {
            return !!q.commodityCode;
        }
    }

    function search(q) {
        if (queryIsValid(q)) {
            var queryString = searchService.serializeQuery(q);
            common.$location.path('/search/tree').search(queryString);
        }
    }

    function loadSearchResults(q) {
        if (!queryIsValid(q)) {
            return;
        }
        if (!q.commodityCode) {
            
            var queryString = searchService.serializeQuery(q);
            common.$location.path('/search/heading').search(queryString).replace();
        }

        vm.q = q;
        
        dataContext.cancel('GOOD');
        vm.isLoading = true;
        state.currentIndentation = null;

        if (!state.remember.country && !q.country) {
            state.filter.countryCode = null;
            state.filter.countryName = null;
        }
        
        if (!q.query) {

            // This is browsing, not searching. We can split this up.

            if (!q.commodityCode) {
                common.$location.url('');
            }
            var chapterCode = q.commodityCode.substring(0, 2);

            if (formatting.isChapterCode(q.commodityCode)) {
                common.$location.url('/chapter/' + chapterCode).replace();
            }
            vm.matchedGoodsCode = true;

            $analytics.pageTrack('/nomenclature-search/by-code');

            nomenclatureDataService.browseHeading(q)
                .then(function (data) {
                    vm.searchType = 'browse'; // At some point we should just split browsing and searching into two different views
                    vm.isLoading = false;
                    if (!state.navigatingFromMeasuresPage && nomenclatureTreeService.isDeclarableCode(q.commodityCode, data.tree)) {
                        dataContext.cancel('SEARCH');
                        common.$location
                            .url('/good/' + formatting.goodsCodeToGoodsnomId(q.commodityCode))
                            .replace();
                    }
                    else {
                        setSectionAndChapter(data.metadata.sectionNumber, data.metadata.chapterNumber);
                        nomenclatureTreeService.setCodeFromAndCodeTo(data.tree);
                        setEcicsCount(data.tree);
                        setCurrentIndentation(data.tree);
                        vm.metadata = data.metadata;
                        vm.heading = data.tree;
                        vm.lastQuery = data.query;
                    }
                    state.navigatingFromMeasuresPage = false;
                },
                function () {
                    vm.isLoading = false;
                });
        }
        else if (q.commodityCode === null || formatting.isChapterCode(q.commodityCode)) {
            common.$location.path('/search/heading/').replace();
        }
        else {
            if (q.commodityCode) {
                $analytics.pageTrack('/nomenclature-search/by-text-and-code');
            }
            else {
                $analytics.pageTrack('/nomenclature-search/by-text');
            }
            vm.matchedGoodsCode = false;

            
            nomenclatureDataService.searchTree(q)
                .then(function (data) {
                    vm.isLoading = false;

                    if (!state.navigatingFromMeasuresPage && nomenclatureTreeService.isDeclarableCode(q.commodityCode, data.nomenclatureResult.tree)) {
                        dataContext.cancel('SEARCH');
                        common.$location
                            .url('/good/' + formatting.goodsCodeToGoodsnomId(q.commodityCode))
                            .replace();
                    }
                    else {
                        nomenclatureTreeService.setCodeFromAndCodeTo(data.nomenclatureResult.tree);
                        setEcicsCount(data.nomenclatureResult.tree);
                        setSectionAndChapter(data.nomenclatureResult.metadata.sectionNumber, data.nomenclatureResult.metadata.chapterNumber);
                        setCurrentIndentation(data.nomenclatureResult.tree);
                        vm.metadata = data.nomenclatureResult.metadata;
                        vm.heading = data.nomenclatureResult.tree;
                        vm.foundTexts = data.foundTexts;
                        vm.lastQuery = data.query;
                        vm.lastQuery.Heading = data.query.RootCommodityCode.substring(0, 4);
                        vm.numberOfResults = data.nomenclatureResult.numberOfResults;
                    }
                    state.navigatingFromMeasuresPage = false;
                }, function(err) {
                    if (!err.isAborted) {
                        try {
                            Sentry.captureException(err);
                        }
                        catch (e) {}
                    }
                });
        }
    }

    function setEcicsCount(tree) {
        if (!tree) {
            return;
        }
        ecicsDataService.countForGoodsCode(tree.value.commodityCode)
            .then(function(numberOfEcicsItems) {
                vm.ecicsCount = {};
                vm.ecicsCount[tree.value.commodityCode] = numberOfEcicsItems;
            });
    }

    function loadChapter(chapterCode) {
        common.$location.path('/chapter/' + chapterCode).search('');
    }

    function hasAtr() {
        return !vm.foundTexts || vm.foundTexts.atrCount > 0;
    }

    function hasBti() {
        return !vm.foundTexts || vm.foundTexts.btiCount > 0;
    }

    function setCurrentIndentation(node) {

        if (node === null) {
            return;
        }
        // Get currentCode from search result from server
        if (node.metadata.isCurrentCode) {
            state.currentCode = node.value.commodityCode;
            state.currentIndentation = node.value.indentation;
        }
        
        if (node.children !== null && node.children.length > 0) {
            _.forEach(node.children, function (child) {
                setCurrentIndentation(child);
            });
        }
    }

    function deselectLine() {
        state.currentCode = null;
        state.currentIndentation = null;
    }

    function showBti(code, $event) {
        btiModal.show(code, vm.q.query);
        $event.preventDefault();
        $event.stopPropagation();
    }

    function showCaseLaw(code, $event) {
        caseLawModal.show(code, vm.q.query);
        $event.preventDefault();
        $event.stopPropagation();
    }

    function showClassificationRegulations(code, $event) {
        classificationRegulationsModal.show(code, vm.q.query);
        $event.preventDefault();
        $event.stopPropagation();
    }

    return vm;
}
