﻿module.exports = ['$uibModal', 'complianceDataService', complianceInfoModal];

function complianceInfoModal($uibModal, complianceData) {
    var modal = {
        show: showModal
    };

    complianceInfoModal.$inject = ['$uibModalInstance', 'jobId'];

    function complianceInfoModalController($uibModalInstance, jobId) {

        var ctrlComplianceInfoModal = {
            cancel: cancel,
            isLoading: false,
            jobId: jobId,
            warnings: []
        };

        function cancel() {
            //            $(".page-dashboard, .taric-page-content").removeClass("noprint");
            $uibModalInstance.dismiss('cancel');
        }

        function loadInfo() {
            ctrlComplianceInfoModal.isLoading = true;
            complianceData.getJobLogs(ctrlComplianceInfoModal.jobId)
                .then(function (result) {
                    ctrlComplianceInfoModal.warnings = _.map(_.filter(result, function (f) { return f.MessageCode !== 'code_found'; }), function (item) {
                        switch (item.LogType) {
                            case 0:
                                item.CssClass = '';
                                item.LogTypeDisplay = 'Not set';
                                break;
                            case 1:
                                item.CssClass = 'danger';
                                item.LogTypeDisplay = 'System error';
                                break;
                            case 2:
                                item.CssClass = 'info';
                                item.LogTypeDisplay = 'Info';
                                break;
                            case 3:
                                item.CssClass = 'warning';
                                item.LogTypeDisplay = 'Warning';
                                break;
                            case 4:
                                item.CssClass = 'danger';
                                item.LogTypeDisplay = 'Content error';
                                break;
                            case 5:
                                item.CssClass = 'danger';
                                item.LogTypeDisplay = 'User error';
                                break;
                        }

                        return item;
                    });
                    ctrlComplianceInfoModal.isLoading = false;
                });
        }

        loadInfo();

        return ctrlComplianceInfoModal;
    }

    function showModal(jobId) {

        return $uibModal.open({
            animation: true,
            backdrop: true,
            controller: complianceInfoModalController,
            controllerAs: 'ctrlComplianceInfoModal',
            keyboard: true,
            modalFade: true,
            resolve: {
                jobId: function () {
                    return jobId;
                }
            },
            size: 'lg',
            templateUrl: 'compliance/modals/compliance-info.html',
            windowClass: 'modal-wide'
        });
    }

    return modal;
}
